import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../../components/MetaTags';
import { theme } from '../../styles/theme';
import Background from '../../components/Background';
import Header from '../../components/Header2';
import HowGroupGiftsWork from '../../sections/occasion/HowGroupGiftsWork';
import CuratedGifts from '../../sections/occasion/CuratedGifts';
import GroupCard from '../../sections/occasion/GroupCard';
import LoveIllume from '../../sections/occasion/LoveIllume';
import JoinOccasions from '../../sections/JoinOccasions';
import Footer from '../../sections/common/FooterWithNav';
import data from '../../sections/occasion/data';
import HeaderSection from '../../sections/occasion/HeaderSection';
import futureCelebrating from '../../images/occasion/farewell.png';
import futurePhone from '../../images/occasion/iPhone12.png';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionFarewell: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={'Create easy and meaningful Farewell ecards and gifts with illume.'}
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={'Farewell Gifts That Stand Out'}
          paragraphs={[
            'Peter has been at ROX for 20 years. Carrie decides to celebrate his farewell at the company with a group gift from illume.',
            'She picks out a unique gift from illume’s digital marketplace, invites her colleagues to contribute with cash, messages, photos, and more – and then hits send when it’s time for his send-off.',
          ]}
          buttonLink={
            'https://web.illumenotes.com/receive?c=8c0019af-69ce-48d4-9832-95ebf7641cb2&email=email@tryillume.com'
          }
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Farewell',
            },
            phone: {
              image: futurePhone,
              label: 'Farewell Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions joinMessage={'of celebrating milestones'} />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionFarewell;
